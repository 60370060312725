import Image from 'next/image';
import { PromotionContext } from '../../pages/_app';
import { useContext } from 'react';

const OurFormula = () => {
    let { promo } = useContext(PromotionContext);
    return (
        <section className={`relative flex flex-col bg-blue-light-bg-new-testimonials ${promo ? 'pt-14' : 'pt-0'}`} id='!acerca-de-fixat'>
            <div className="w-full text-center" >
                <div className="xs:hidden md:block">
                    <Image
                        src="https://cdn.fixat.mx/assets/home/bg/no-webp/bg_005.png"
                        alt="Fondo de fórmula fixat"
                        objectFit="cover"
                        layout="fill"
                        quality={100}
                        loading="lazy"
                    />
                </div>
                <div className="xs:block md:hidden z-0">
                    <Image
                        src="https://cdn.fixat.mx/assets/home/bg/no-webp/bg_005_mob.png"
                        alt="Fondo de fórmula fixat"
                        layout="fill"
                        className="z-0"
                        objectFit="cover"
                        quality={100}
                        loading="lazy"
                    />
                </div>
                <div className="pw z-10">
                    <h2 className="font-fredoka text-4.5xl text-center w-full float-left py-20">
                        Nuestra fórmula
                    </h2>
                    <div className="max-w-screen-md2x mx-auto">
                        <div className="w-full flex md:flex-row flex-col items-center justify-center text-center">
                            <div className="md:w-1/3 w-full">
                                <div className='relative w-full h-[132px] '>
                                    <Image
                                        src='https://cdn.fixat.mx/assets/img/no-webp/fixat-robot.png'
                                        title="Tecnología"
                                        alt="Fixat Tecnología"
                                        loading="lazy"
                                        layout='fill'
                                        objectFit='contain'
                                    />
                                </div>
                                <h3 className="text-lg text-dark-blue font-fredoka tracking-widest block xs:pb-12 md:pb-0">
                                    Tecnología
                                </h3>
                            </div>
                            <Image
                                src={"https://cdn.fixat.mx/assets/bg/no-webp/plus_icon.png"}
                                loading="lazy"
                                alt="Contadores expertos"
                                title="Contadores expertos"
                                objectFit='contain'
                                width={32}
                                height={35}
                            />
                            <div className="md:w-1/3 w-full mmd:pt-10">
                                <div className='relative w-full h-[132px]'>
                                    <Image loading="lazy"
                                        src='https://cdn.fixat.mx/assets/img/no-webp/asesores.png'
                                        alt="Contadores expertos"
                                        title="Contadores expertos"
                                        layout='fill'
                                        objectFit='contain'
                                    />
                                </div>
                                <h3 className="text-lg text-dark-blue font-fredoka tracking-widest block xs:pb-16 md:pb-0">
                                    Contadores expertos
                                </h3>
                            </div>
                            <Image
                                src={"https://cdn.fixat.mx/assets/bg/no-webp/equals_icon.png"}
                                loading="lazy"
                                alt="Contadores expertos"
                                title="Contadores expertos"
                                objectFit='contain'
                                width={32}
                                height={35}
                            />
                            <div className="md:w-1/3 w-full">
                                <div className="xs:mt-0 md:mt-5">
                                    <div className='relative w-full h-[132px]'>
                                        <Image loading="lazy"
                                            src='https://cdn.fixat.mx/assets/img/no-webp/logo-de-formula-fixat.png'
                                            alt="Logo de fórmula FIXAT®"
                                            title="FIXAT®"
                                            id="img_017"
                                            layout='fill'
                                            objectFit='contain'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="w-full flex justify-center text-center mt-12">
                            <p className="xs:w-full md:w-5/12">En FIXAT®, aprovechamos la tecnología para hacer más fácil el proceso de declarar tus impuestos, siempre acompañados de contadores expertos y actualizados.
                                <span className="block mt-6 mb-8">Nuestros contadores son 100% profesionales y están al día con las reformas a la ley. Con FIXAT®, tus declaraciones están bien hechas.
                                </span>
                            </p>
                        </div>
                    </div>
                    <div className="flex w-full justify-center pt-20 md:pt-0">
                        <div className='relative w-full xs:h-[180px] md:h-[389px]'>
                            <Image loading="lazy"
                                src="https://cdn.fixat.mx/assets/home/img/contadores-eticos-y-confiables.png"
                                alt="contadores éticos y confiables"
                                title="contadores éticos y confiables"
                                layout='fill'
                                objectFit='contain'
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full relative float-left text-center pt-8 mt-0 bg-pastel-blue">
                <div className="pw">
                    <div className="w-full mt-12 md:mt-0 text-center">
                        <h3 className="float-left w-full text-3xl font-fredoka tracking-widest pb-5">
                            Contadores avalados por:
                        </h3>
                        <span className="float-left w-full text-2xl font-fredoka font-extrabold mb-12">
                            <span className="xs:block lgx:inline-block xs:mr-0 lgx:mr-12">Instituto Mexicano de Contadores Públicos
                            </span>
                            <span className="">Asociación Mexicana de Contadores Públicos
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default OurFormula;